import { loadSiteTypes, loadSiteTypeSuccess } from './../actions/site.action';
/** effect for user list api call */

import { Injectable } from '@angular/core';
import { SortSearchAndPaginationModal } from '@models/common.model';
import { SiteCreateAPIRequest, SiteType } from '@models/sites.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import {
  addSiteError,
  addSites,
  deleteSite,
  deleteSiteError,
  getSite,
  getSiteError,
  getSiteSuccess,
  loadSiteError,
  loadSiteSuccess,
  loadSiteWithSearch,
  loadSiteWithSearchSuccess,
  loadSites,
  resetStateAfterOperation,
  resetStateSuccess,
  siteOperationMessage,
  updateSite,
  updateSiteError,
} from '../actions/site.action';
import { SiteAPIService } from '../services/sites.service';

@Injectable()
export class SiteAPIEffects {
  loadSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSites),
      mergeMap((param: SortSearchAndPaginationModal) => {
        return this.siteAPIService.getSites(param).pipe(
          map((sites) => {
            return loadSiteSuccess({ sites });
          }),
          catchError(async (error) => loadSiteError({ error }))
        );
      })
    )
  );

  loadSiteWithSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSiteWithSearch),
      mergeMap((param: SortSearchAndPaginationModal) => {
        return this.siteAPIService.getSites(param).pipe(
          map((sites) => {
            return loadSiteWithSearchSuccess({ sites });
          }),
          catchError(async (error) => loadSiteError({ error }))
        );
      })
    )
  );

  addSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSites),
      mergeMap((param: { site: SiteCreateAPIRequest }) =>
        this.siteAPIService.addSite(param.site).pipe(
          map((sites) => {
            return siteOperationMessage({
              message: 'Site Created Successfully!',
            });
          }),
          catchError(async (error) => addSiteError({ error }))
        )
      )
    )
  );

  getSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSite),
      mergeMap((param: { id: string | number }) =>
        this.siteAPIService.getSiteDetail(param.id).pipe(
          map((site) => {
            return getSiteSuccess({ site: site });
          }),
          catchError(async (error) => getSiteError({ error }))
        )
      )
    )
  );

  updateSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSite),
      mergeMap((param: { id: string | number; site: SiteCreateAPIRequest }) =>
        this.siteAPIService.updateSiteDetail(param.id, param.site).pipe(
          map((sites) => {
            return siteOperationMessage({
              message: 'Site Updated Successfully!',
            });
          }),
          catchError(async (error) => updateSiteError({ error }))
        )
      )
    )
  );

  deleteSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSite),
      mergeMap((param: { id: string | number }) =>
        this.siteAPIService.deleteSiteDetail(param.id).pipe(
          map((sites) => {
            return siteOperationMessage({
              message: 'Site Deleted Successfully!',
            });
          }),
          catchError(async (error) => deleteSiteError({ error }))
        )
      )
    )
  );

  resetStateAfterOperation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resetStateAfterOperation),
      mergeMap(() => {
        return of(resetStateSuccess());
      })
    );
  });

  constructor(
    private actions$: Actions,
    private siteAPIService: SiteAPIService
  ) {}
}

import { LoadListSearch } from './loads.model'
import { SiteDetail } from '@models/sites.model'

export interface Pagination {
    itemCount: number
    pageCount: number
    currentPage: number
    pageSize: number
}

/**grid models */
export interface GridPaginationModal {
    previousPageIndex: number
    pageIndex: number
    pageSize: number
    length: number
}

export interface GridSortModal {
    sortField: string
    sortDirection: string
}

export interface SortAndPaginationModal {
    sortField?: string
    sortDir?: string
    currentPage?: number
    pageSize?: number
    pageNumber?: number
}

export interface SortSearchAndPaginationModal {
    filter?: { siteId?: number; dateFrom?: string; dateTo?: string; siteType?: string; status?: string }
    userListFilter?: { type?: string; role?: string }
    search?: string
    pagination?: SortAndPaginationModal
    searchFilterObj?: dynamicObject
}

export interface dynamicObject {
    [key: string]: any
}
export interface paginationQueryParam {
    sortField?: string
    sortDir?: string
    pageNumber: number
    pageSize: number
}

export interface ErrorInAPI {
    code: string
    message: string
}

export interface ColumnDefTemplate {
    key: string
    header: string
    template?: string
    templateMapValue?: string[]
}

export interface UserTypeDetail {
    id: number
    description: string
    updatedAt: string
    createdAt: string
}

export interface UserRoleDetail {
    id: number
    role: string
    updatedAt: string
    createdAt: string
}

export interface IDropdown {
    id: number
    name: string
}

export interface IDropdownStringOnly {
    id: string
    name: string
}

export interface SideBarMenu {
    label: string
    icon: string
    path: string
}

export function isObject(params: any) {
    return typeof params === 'object'
}

export function isString(params: any) {
    return typeof params === 'string'
}

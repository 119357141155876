import { Pagination } from './common.model'
import { TransportOrder, TransportPermitDetail } from './transport-permit.model'
import { UserDetails } from './user.model'

export interface ILoadsState {
    loads?: LoadListResponse
    loading?: boolean
    activeTab?: number
    paginationObj: any
    searchTxt?: string | ''
    searchObj?: LoadListSearch | {}
    error?: any
    opSuccess?: string
    opFail?: string
    load: { loads: LoadEventList[] }
    loadDetail: { loadDetail: LoadItem }
    imageData: any | {}
}

export interface LoadListResponse {
    data: any[]
    meta: Pagination | undefined
}

export interface LoadListSearch {
    siteId: number
    dateFrom: Date
    dateTo: Date
    status?: string
}

export interface LoadDetailObject {
    loadId: number
    status: string
    truck: { regNumber: string }
    transporter: number
    destinationSite: { code: string }
    originSite: { code: string }
    weighbridgeDoc: { nettWeight: number }
    type: string
    createdAt: string
}

export interface ImageData {
    id: number
    loadEventId: number
    type: string
    externalId: string
    createdAt: string
    updatedAt: string
    updatedBy: string
    createdBy: string
    imageUrl: string
    lat?: number
    long?: number
    displayValue?: string
    status?: string
}

export interface GetLoadDetail {
    id: string
    processType?: string
}

export interface LoadById {
    id: string
}

export interface LoadListApiResponse {
    totalCount: number
    totalPages: number
    pageNumber: number
    items: LoadItem[]
}

export interface LoadItem {
    loadId?: number
    status?: string
    truck?: Driver | null
    type?: string | ''
    driver?: Driver | null
    weighbridgeDoc?: WeighbridgeDoc | null
    originSite?: LoadSiteDetail | null
    destinationSite?: LoadSiteDetail | null
    transportPermitDto?: TransportPermitDetail
    trailerDetails?: any[]
    hasException?: boolean | null
    createdAt?: Date
    updatedAt?: Date
    createdBy?: string
    updatedBy?: string
    caseId?: number
    seals?: Array<{
        id: number
        trailerId: number
        trailerReg: string
        sealNumber: string
        createdAt: string
        updatedAt: string
        createdBy: {
            id: number
            uid: string
            type: {
                id: number
                description: string
                createdAt: string
                updatedAt: string
            }
            firstName: string
            lastName: string
            email: string
            mobileNumber: string
            role: {
                id: number
                role: string
                createdAt: string
                updatedAt: string
            }
            organizationId: number
            lastActive: string | null
            createdAt: string
            updatedAt: string
        }
        updatedBy: UserDetails
    }>
}

export interface Driver {
    id: number | null
    licenseNumber?: string
    regNumber?: string
    createdAt?: string
    updatedAt?: string
    createdBy?: string
    updatedBy?: string
}

export interface LoadSiteDetail {
    id: number
    displayName: string
    code: string
    latitude: number
    longitude: number
    type: string
}

export interface WeighbridgeDoc {
    ticketNumber: number | null
    nettWeight: number | null
}

export interface ParamUpdateLoad {
    id: number
    status: string
    actionType: string
    loadStatus: string
    caseId?: number
    loadId?: number
    prevValue?: string
    nextValue?: string
}

export interface LoadEventList {
    reduce(arg0: (group: any, param: any) => any, arg1: {}): unknown
    map(arg0: (val: any, key: any) => void): unknown
    filter(arg0: (o: { [x: string]: any }) => false | Set<unknown>): unknown
    id: number
    type: string
    content?: string
    processType: string
    status: string
    statusMessage: string | null
    loadId: number
    site?: LoadSiteDetail
    deviceId: number | null
    regNumber: null | string
    licenseNumber: number | null
    weighbridgeRefNo: null | string
    nettWeight: null | string
    latitude: number
    longitude: number
    startTimestamp?: Date | string
    endTimestamp?: Date | string
    images: ImageData[]
    createdAt?: Date | string
    updatedAt?: Date | string
    createdBy: number
    createdByEmail: string
    updatedBy?: number
    updatedByEmail?: string
    sealNumber?: string
}

export interface ManualEventData {
    driver: string
    horseReg: string
    trailers: {
        regNumber: string
        seals: string[]
    }[]
    weighbridgeRefNo: string
    nettWeight: string
}

export const InitialStateValue: ILoadsState = {
    loads: { data: [], meta: undefined },
    loading: false,
    error: null,
    activeTab: 0,
    paginationObj: {
        sortField: 'createdAt',
        sortDir: 'DESC',
        currentPage: 0,
        pageSize: 20,
    },
    searchTxt: '',
    searchObj: {},
    load: {
        loads: [
            {
                reduce: function (arg0: (group: any, param: any) => any, arg1: {}): unknown {
                    throw new Error('Function not implemented.')
                },
                map: function (arg0: (val: any, key: any) => void): unknown {
                    throw new Error('Function not implemented.')
                },
                filter: function (arg0: (o: { [x: string]: any }) => false | Set<unknown>): unknown {
                    throw new Error('Function not implemented.')
                },
                id: 0,
                type: '',
                processType: '',
                status: '',
                statusMessage: null,
                loadId: 0,
                deviceId: null,
                regNumber: null,
                licenseNumber: null,
                weighbridgeRefNo: null,
                nettWeight: null,
                latitude: 0,
                longitude: 0,
                images: [],
                createdBy: 0,
                createdByEmail: '',
                updatedBy: 0,
                updatedByEmail: '',
            },
        ],
    },
    loadDetail: { loadDetail: {} },
    imageData: {},
}

type Site = {
    id: number
    displayName: string
    code: string
    latitude: number
    longitude: number
    type: string
    createdAt: string
    updatedAt: string
    createdBy: string
    updatedBy: string
}

type Image = {
    id: number
    loadEventId: number
    type: string
    externalId: string
    createdAt: string
    updatedAt: string
    updatedBy: string
    createdBy: string
    imageUrl: string
}

export type LoadEvent = {
    id: number
    type: string
    processType: string
    status: string
    statusMessage: string | null
    loadId: number
    site: Site
    deviceId: string | null
    regNumber: string | null
    licenseNumber: string | null
    weighbridgeRefNo: string | null
    nettWeight: number | null
    latitude: number
    longitude: number
    startTimestamp: string
    endTimestamp: string
    images: Image[]
    createdAt: string
    updatedAt: string
    createdBy: number
    createdByEmail: string
    updatedBy: number
    updatedByEmai?: string
    sealNumber?: string | null
    content?: string | null
}

export type LoadEventUpdate = {
    type: string
    prevValue?: string
    nextValue: string
    attributes: {}
}

type LoadEventsApiResponse = LoadEvent[]

export default LoadEventsApiResponse

export type PermitApiResponse = {
    id: number
    transportOrder: TransportOrder
    permitDate: string
    driver: {
        id: number
        licenseNumber: string
        createdAt: string
        updatedAt: string
        createdBy: string
        updatedBy: string
    }
    truck: {
        id: number
        regNumber: string
        createdAt: string
        updatedAt: string
        createdBy: string
        updatedBy: string
    }
    firstTrailer: {
        id: number
        regNumber: string
        createdAt: string
        updatedAt: string
        createdBy: string
        updatedBy: string
    }
    secondTrailer: {
        id: number
        regNumber: string
        createdAt: string
        updatedAt: string
        createdBy: string
        updatedBy: string
    }

    createdAt: string
    updatedAt: string
    createdBy: string
    status: string
    updatedBy: string
}
type Seal = string

type Trailer = {
    regNumber: string
    seals: Seal[]
}

export type ManualOriginEntry = {
    weighbridgeRefNo?: string
    nettWeight?: string
    horseReg?: string
    driver?: string
    trailers: Trailer[]
}

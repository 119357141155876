import { addOrganisation, addOrganisationError, getOrganisation, getOrganisationSuccess, loadOrganisationError, loadOrganisations, loadOrganisationSuccess, organisationOperationMessage, updateOrganisation, updateOrganisationError } from '@actions/organisation.action';
import { IOrganisationState } from '@models/organisations.model';
import { createReducer, on } from '@ngrx/store';
import {
  resetStateAfterOperation
} from '../actions/site.action';

/**user reducer for state change */
const initialState: IOrganisationState = {
  paginationObj: {
    'sortField': '',
    'sortDir': 'ASC',
    'currentPage': 0,
    'pageSize': 20
  },
  organisations: { data: [], meta: undefined },
  organisation: null,
  loading: false,
  error: null,
  opSuccess: '',
};

export const organisationReducer = createReducer(
  initialState,
  on(loadOrganisations, (state, action) => {
    return ({ ...state, loading: true })
  }),
  on(loadOrganisationSuccess, (state, { organisations }) => ({
    ...state,
    paginationObj: organisations.meta,
    organisations,
    loading: false,
  })),
  on(loadOrganisationError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(organisationOperationMessage, (state, action) => {
    return { ...state, opSuccess: action.message };
  }),
  on(getOrganisation, (state) => {
    return { ...state, loading: true };
  }),
  on(getOrganisationSuccess, (state, action) => {
    console.log("organisaiton detail", state, action);
    return { ...state, organisation: action.organisation, loading: false };
  }),
  on(addOrganisation, (state, action) => {
    return { ...state, loading: true }
  }),
  on(addOrganisationError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(updateOrganisation, (state, action) => {
    return { ...state, loading: true }
  }),
  on(updateOrganisationError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(resetStateAfterOperation, (state, action) => {
    return {
      paginationObj: {},
      organisations: { data: [], meta: undefined },
      organisation: null,
      loading: false,
      error: null,
      opSuccess: ''
    };
  })
);

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectUser } from '@selectors/auth.selectors';
import { SnackbarService } from '@shared/services/snackbar.service';

@Injectable()
export class HTTPInterceptor implements HttpInterceptor {
  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private store: Store<{}>,
    private _snackbar: SnackbarService
  ) {}
  ngOnInit() {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.auth.user.pipe(
      switchMap((user) => {
        if (user) {
          return from(user.getIdToken());
        } else {
          return of(null);
        }
      }),
      switchMap((idToken: string | null) => {
        if (idToken && idToken !== null) {
          //set token to call api
          return next.handle(
            request.clone({
              setHeaders: {
                Authorization: `Bearer ${idToken}`,
              },
            })
          );
        } else {
          if (this.router.url !== '/login') {
            return throwError(
              () => new Error('Please login to access the cometa system!!')
            );
          } else {
            this._snackbar.showSuccessSnackbar(
              'You Logged Out Successfully!!! Please login again to enter the cometa system.'
            );

            return throwError(() => new Error());
          }
        }
      }),
      catchError((requestError: HttpErrorResponse) => {
        return throwError(() => requestError);
      })
    );
  }
}

<div class="wrapper" (keydown.enter)="onLoginClick()">
  <mat-card class="mat-elevation-z4">
    <mat-card-content>
      <div class="wrapper-1">
        <div class="wrapper-2">
          <div class="logo-wrapper">
            <img src="{{ logo_imgSrc }}" />
            <span class="login-header">Welcome to Zeta</span>
          </div>
          <div>
            <!-- <app-form [formToRrnder]="loginForm" (onSubmitLoginDetails$)="onLoginClick($event)"></app-form> -->
            <form [formGroup]="loginForm" class="login-form">
              <mat-form-field appearance="outline" style="margin: 6px">
                <input
                  matInput
                  placeholder="Enter Email"
                  formControlName="email"
                  type="email"
                  label="Email"
                  iconName="email"
                />
                <mat-icon
                  matSuffix
                  *ngIf="loginForm.hasError('email') && !loading"
                  style="color: red"
                  >error</mat-icon
                >
                <mat-error
                  *ngIf="loginFormError('email', 'required')"
                  style="margin: 6px"
                  >{{ ValidationMessage.EMAIL_REQUIRED }}</mat-error
                >
                <mat-error *ngIf="loginFormError('email', 'email')">{{
                  ValidationMessage.EMAIL_INVALID
                }}</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" style="margin: 6px">
                <input
                  matInput
                  placeholder="Enter Password"
                  formControlName="password"
                  [type]="hide ? 'password' : 'text'"
                  label="Password"
                  iconName="password"
                />
                <button
                  mat-icon-button
                  matSuffix
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                  <mat-icon>{{
                    hide ? "visibility_off" : "visibility"
                  }}</mat-icon>
                </button>
                <mat-icon
                  matSuffix
                  *ngIf="loginForm.hasError('password') && !loading"
                  style="color: red"
                  >error</mat-icon
                >
                <mat-error
                  *ngIf="loginFormError('password', 'required')"
                  style="margin: 6px"
                  >{{ ValidationMessage.PASSWORD_REQUIRED }}</mat-error
                >
                <mat-error *ngIf="loginFormError('password', 'pattern')">{{
                  ValidationMessage.PASSWORD_INVALID
                }}</mat-error>
              </mat-form-field>

              <button
                mat-button
                (click)="onLoginClick()"
                class="login-btn"
                style="margin: 6px"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

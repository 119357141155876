import {createFeature, createReducer, on} from "@ngrx/store";
import {GetPermitDetailInitialState} from "@module/load-management/models/manual-loads.models";
import {getPermitDetailsAction} from "@module/load-management/store/actions";

const initialGetLoadByRegNumberState: GetPermitDetailInitialState = {
  getPermitDetailIsLoading: false,
  getPermitDetailError: '',
  getPermitDetailResponse: null,
}

const getPermitDetailFeature = createFeature({
  name: 'getGetPermitDetail',
  reducer: createReducer(
    initialGetLoadByRegNumberState,
    on(getPermitDetailsAction.requestPermitDetails, (state) => ({ ...state, getPermitDetailIsLoading: true })),
    on(getPermitDetailsAction.requestPermitDetailsSuccess, (state, event) => ({
      ...state,
      getPermitDetailIsLoading: false,
      getPermitDetailResponse: event.permitDetail,
    })),
    on(getPermitDetailsAction.requestPermitDetailsFailed, (state, event) => ({
      ...state,
      getPermitDetailIsLoading: false,
      getPermitDetailError: event.error,
      getPermitDetailResponse: null,
    })),
    on(getPermitDetailsAction.requestPermitDetailsClearState, (state, event) => ({
      ...initialGetLoadByRegNumberState,
    }))
  ),
})

export const {
  name: getPermitDetailFeatureKey,
  reducer: getPermitDetailReducer,
  selectGetPermitDetailIsLoading,
  selectGetPermitDetailError,
  selectGetPermitDetailResponse
} = getPermitDetailFeature

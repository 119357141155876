import {
  addTransportPermitError,
  getTransportPermit,
  getTransportPermitError,
  getTransportPermitSuccess,
  loadTransportPermit,
  loadTransportPermitError,
  loadTransportPermitSuccess,
  loadTransportPermitWithSearchSuccess,
  resetStateAfterOperation,
  transportPermitOperationMessage,
  updateTransportPermitError,
} from '@actions/transport-permits.action';
import { ITransportPermitState } from '@models/transport-permit.model';
import { createReducer, on } from '@ngrx/store';

/**user reducer for state change */
const initialState: ITransportPermitState = {
  paginationObj: {
    sortField: 'createdAt',
    sortDir: 'DESC',
    currentPage: 0,
    pageSize: 20,
  },
  transportPermitList: {
    data: [],
    meta: {
      itemCount: 0,
      pageCount: 0,
      currentPage: 0,
      pageSize: 20,
    },
  },
  transportPermit: null,
  loading: false,
  error: null,
  opSuccess: '',
};

export const transportPermitReducer = createReducer(
  initialState,
  on(loadTransportPermit, (state, action) => {
    return { ...state, loading: true };
  }),
  on(loadTransportPermitSuccess, (state, { transportPermitList }) => {
    return {
      ...state,
      paginationObj: transportPermitList.meta,
      transportPermitList: transportPermitList,
      loading: false,
    };
  }),
  on(loadTransportPermitError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(
    loadTransportPermitWithSearchSuccess,
    (state, { transportPermitList }) => ({
      ...state,
      paginationObj: transportPermitList.meta,
      transportPermitList,
      loading: false,
    })
  ),
  on(getTransportPermit, (state, action) => {
    return { ...state, loading: true };
  }),
  on(getTransportPermitSuccess, (state, action) => {
    return {
      ...state,
      transportPermit: action.transportPermit,
      loading: false,
    };
  }),
  on(getTransportPermitError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(updateTransportPermitError, (state, action) => {
    return { ...state, error: action.error, loading: false };
  }),
  on(addTransportPermitError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(transportPermitOperationMessage, (state, action) => {
    return { ...state, opSuccess: action.message };
  }),
  on(resetStateAfterOperation, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      contract: null,
      opSuccess: '',
    };
  })
);

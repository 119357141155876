import { IErrorResponse } from '../../models/errorResponse.model';

export interface ISharedState {
  showLoading: boolean;
  errorMessage: IErrorResponse | null;
  isExpand: boolean;
}

export const initialState: ISharedState = {
  showLoading: false,
  errorMessage: null,
  isExpand:false,
};

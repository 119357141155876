/** handle http error */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackbarService } from '@shared/services/snackbar.service';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _snackbar: SnackbarService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((requestError: any) => {
        if (requestError.status !== 401) {
          const { error } = requestError;
          if (error.message) {
            this._snackbar.showErrorSnackbar({
              message: error.message,
            });
          }
        }
        return throwError(() => requestError);
      })
    );
  }
}

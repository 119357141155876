/** effect for user list api call */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  mergeMap,
} from 'rxjs';

import {
  TransportPermitActions,
  addTransportPermit,
  addTransportPermitError,
  loadTransportPermit,
  loadTransportPermitError,
  loadTransportPermitSuccess,
  loadTransportPermitWithSearch,
  loadTransportPermitWithSearchSuccess,
  transportPermitOperationMessage,
} from '@actions/transport-permits.action';
import {
  TransportPermitActionId,
  TransportPermitDetail,
} from '@models/transport-permit.model';
import { TransportPermitAPIService } from '@services/transport-permit.service';

@Injectable()
export class TransportPermitEffects {
  loadTransportPermits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTransportPermit),
      mergeMap((param) =>
        this.transportPermitAPIService.getTransportPermitList(param).pipe(
          map((transportPermit) =>
            loadTransportPermitSuccess({
              transportPermitList: transportPermit,
            })
          ),
          catchError(async (error) => {
            return loadTransportPermitError({ error });
          })
        )
      )
    )
  );

  addPermit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTransportPermit),
      mergeMap((param) =>
        this.transportPermitAPIService
          .addTransporPermit(param.transportPermit)
          .pipe(
            map((permit) =>
              transportPermitOperationMessage({
                message: 'Transport permit Added Successfully',
              })
            ),
            catchError(async (error) => {
              return addTransportPermitError({ error });
            })
          )
      )
    )
  );

  getTransportPermits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransportPermitActions.getTransportPermit),
      mergeMap((param: TransportPermitActionId) =>
        this.transportPermitAPIService.getTransportPermitDetail(param.id).pipe(
          map((permit) =>
            TransportPermitActions.getTransportPermitSuccess({
              transportPermit: permit,
            })
          ),
          catchError(async (error) => {
            return TransportPermitActions.getTransportPermitError({ error });
          })
        )
      )
    )
  );

  updateTransportPermit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransportPermitActions.updateTransportPermit),
      mergeMap(
        (param: {
          id: string | number;
          transportPermit: TransportPermitDetail;
        }) =>
          this.transportPermitAPIService
            .updateTransportPermitDetail(param.id, param.transportPermit)
            .pipe(
              map((permit) =>
                transportPermitOperationMessage({
                  message: 'Transport permit updated Successfully',
                })
              ),
              catchError(async (error) => {
                return TransportPermitActions.updateTransportPermitError({
                  error,
                });
              })
            )
      )
    )
  );

  loadTransportPermitWithSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTransportPermitWithSearch),
      mergeMap((param) => {
        return this.transportPermitAPIService
          .getTransportPermitList(param)
          .pipe(
            map((transportPermit) => {
              return loadTransportPermitWithSearchSuccess({
                transportPermitList: transportPermit,
              });
            }),
            catchError(async (error) => loadTransportPermitError({ error }))
          );
      })
    )
  );

  constructor(
    private transportPermitAPIService: TransportPermitAPIService,
    private actions$: Actions
  ) {}
}

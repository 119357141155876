import { resetStateSuccess } from '@actions/site.action';
import { createReducer, on } from '@ngrx/store';
import {
  loadAdminUserError,
  loadAdminUserSuccess,
  loadAgentUserError,
  loadAgentUserSuccess,
  loadAdminUsers,
  loadAgentUsers,
  userOperationMessage,
  addUser,
  addUserError,
  userDetailbyIdSuccess,
  setActiveTab,
  userDetailbyId,
  userDetailError,
  userDetailbyIdError,
  updateUserError,
  updateUser,
  updateUserPassword,
  resetStateAfterOperation,
  loadTransporterUserSuccess,
  loadTransporterUsers,
  loadAdminWithSearchSuccess,
  loadAgentWithSearchSuccess,
  loadTransporterWithSearchSuccess,
} from '../actions/users.action';
import { IUsersState } from '../models/user.model';

/**user reducer for state change */
const initialState: IUsersState = {
  activeTab: 0,
  admins: { paginationObj: {
    'sortField': '',
    'sortDir': 'ASC',
    'currentPage': 0,
    'pageSize': 20
  }, data: [], meta: undefined },
  agents: { paginationObj:{
    'sortField': '',
    'sortDir': 'ASC',
    'currentPage': 0,
    'pageSize': 20
  }, data: [], meta: undefined },
  transporters: { paginationObj: {
    'sortField': '',
    'sortDir': 'ASC',
    'currentPage': 0,
    'pageSize': 20
  }, data: [], meta: undefined },
  loading: false,
  userToEdit: null,
  opFail: '',
  opSuccess: '',
  error: null,
};

export const userReducer = createReducer(
  initialState,
  on(loadAdminUsers, (state, action) => {
    let paginationObj = action.pagination;
    let admins = Object.assign({}, state.admins, {
      paginationObj: paginationObj,
    });
    return { ...state, loading: true, admins };
  }),
  on(loadAgentUsers, (state, action) => {
    let paginationObj = action.pagination;
    let agents = Object.assign({}, state.agents, {
      paginationObj: paginationObj,
    });
    return { ...state, loading: true, agents };
  }),
  on(loadAdminUserSuccess, (state, action) => {
    let admins = Object.assign({}, state.admins, action.admins);
    return {
      ...state,
      admins,
      loading: false,
    };
  }),
  on(loadAgentUserSuccess, (state, action) => {
    let agents = Object.assign({}, state.agents, action.agents);
    return {
      ...state,
      agents,
      loading: false,
    };
  }),
  on(loadTransporterUsers, (state, action) => {
    let paginationObj = action.pagination;
    let transporters = Object.assign({}, state.transporters, {
      paginationObj: paginationObj,
    });
    return { ...state, loading: true, transporters };
  }),
  on(loadTransporterUserSuccess, (state, action) => {
    let transporters = Object.assign({}, state.transporters, action.transporters);
    return {
      ...state,
      transporters,
      loading: false,
    };
  }),
  on(loadAdminUserError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),

  on(loadAdminWithSearchSuccess, (state, { admins }) => ({
    ...state,
    paginationObj: admins.meta,
    admins,
    loading: false,
  })),
  on(loadAgentWithSearchSuccess, (state, { agents }) => ({
    ...state,
    paginationObj: agents.meta,
    agents,
    loading: false,
  })),
  on(loadTransporterWithSearchSuccess, (state, { transporters }) => ({
    ...state,
    paginationObj: transporters.meta,
    transporters,
    loading: false,
  })),
  on(userDetailError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(userDetailbyIdError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(loadAgentUserError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(userOperationMessage, (state, action) => {
    let activeTab = action.userType == 1 ? 0 : 1;
    return { ...state, opSuccess: action.message, activeTab, loading: false };
  }),
  on(addUserError, (state, action) => {
    return { ...state, error: action.error, loading: false };
  }),
  on(updateUserError, (state, action) => {
    return { ...state, error: action.error, loading: false }
  }),
  on(updateUserPassword, (state, action) => {
    return { ...state, loading: true }
  }),
  on(updateUser, (state, action) => {
    return { ...state, loading: true }
  }),
  on(addUser, (state, action) => {
    return { ...state, loading: true };
  }),
  on(userDetailbyId, (state, action) => {
    return { ...state, loading: true };
  }),
  on(userDetailbyIdSuccess, (state, action) => {
    return { ...state, userToEdit: action.user, loading: false };
  }),
  on(userDetailbyIdError, (state, action) => {
    return { ...state, error: action.error.message, loading: false }
  }),
  on(setActiveTab, (state, action) => {
    return { ...state, activeTab: action.tab }
  }),
  on(resetStateAfterOperation, (state, action) => {
    return { ...state, loading: false, error: null, site: null, opSuccess: '' };
  }),
  on(resetStateSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      userToEdit: null,
      error: '',
      opFail: '',
      opSuccess: '',
    };
  })
);

import { ICasesState, InitialStateValue } from '@models/cases.model';
import { createReducer, on } from '@ngrx/store';
import {
  getCaseDetailById,
  getCaseDetailByIdFail,
  getCaseDetailByIdSuccess,
  getCaseDetailSuccess,
  loadCaseError,
  loadCases,
  loadCaseSuccess,
  loadCaseWithSearch,
  resetStateAfterOperation,
  updateCase,
  updateCaseSuccess,
  updateCaseFailed,
  getCaseEvents,
  getCaseEventsSuccess,
  getCaseEventFail,
  createCaseEventSuccess,
  createCaseEventFailed,
   createCaseEvent,
} from '../actions/case.action';

/**user reducer for state change */
const initialState: ICasesState = InitialStateValue;

export const caseReducer = createReducer(
  initialState,
  on(loadCases, (state, action) => {
    return { ...state, paginationObj: action.pagination, loading: true };
  }),
  on(loadCaseSuccess, (state, action) => {
    return {
      ...state,
      cases: action.cases,
      paginationObj: action.cases.meta,
      loading: false,
    };
  }),
  on(loadCaseError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(loadCaseWithSearch, (state, action) => {
    return {
      ...state,
      searchObj: action.search,
      paginationObj: action.pagination,
    };
  }),

  on(getCaseDetailById, (state, action) => {
    return { ...state, loading: true };
  }),
  on(getCaseDetailByIdFail, (state, action) => {
    return { ...state, error: action.error };
  }),
  on(getCaseDetailSuccess, (state, action) => {
    return {
      ...state,
      load: action.case,
    };
  }),
  on(getCaseDetailByIdSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      caseDetail: { caseDetail: action.caseDetail },
    };
  }),
  on(resetStateAfterOperation, (state, action) => {
    return { ...state, loading: false, error: null, site: null, opSuccess: '' };
  }),
  on(updateCase, (state, action) => {
    return { ...state, loading: true };
  }),
  on(updateCaseSuccess, (state, action) => {
    return { ...state, loading: false, updateSuccess: true };
  }),
  on(updateCaseFailed, (state, action) => {
    return { ...state, error: action.error, loading: false, updateSuccess: true  };
  }),
  on(getCaseEvents, (state, action) => {

    return { ...state, loading: true };
  }),
  on(getCaseEventsSuccess, (state, action) => {
    console.log(action.events)
    return { ...state, events: action.events, loading: false };
  }),
  on(getCaseEventFail, (state, action ) => {
    console.warn(state.events)
    return { ...state, loading: false, error: action.error };
  }),
  on(createCaseEvent, (state, action) => {
    return { ...state, loading: true };
  }),
  on(createCaseEventSuccess, (state, action) => {
    console.log('here is the new action')

    return {
      ...state,
      events: { ...state.events, items: [...state.events.items] },
      loading: false,
    };
  }),
  on(createCaseEventFailed, (state, action) => {
    return { ...state, loading: false, error: action.error };
  })
);

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, of, Subscription} from 'rxjs';
import { IAppState } from './ngrx/app.state';
import { getErrorMessage, getLoading } from '@store/shared/shared.selector';
import { IErrorResponse } from '@models/errorResponse.model';
import { SnackbarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Cometa';
  showLoading?: Observable<boolean>;
  errorData?: IErrorResponse;
  errorDataSubs: Subscription = new Subscription();
  constructor(
    private _store: Store<IAppState>,
    private _snackbar: SnackbarService,
    private cdref: ChangeDetectorRef
  ) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.showLoading = this._store.select(getLoading);
    this.errorDataSubs = this._store
      .select(getErrorMessage)
      .subscribe((data) => {
        if (data) {
          this.errorData = data;
          this._snackbar.showErrorSnackbar(this.errorData!);
        }
      });
  }

  ngOnDestroy(): void {
    this.errorDataSubs.unsubscribe();
  }
}

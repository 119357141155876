/** effect for user list api call */

import { Injectable } from '@angular/core'
import { SortSearchAndPaginationModal } from '@models/common.model'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, take } from 'rxjs'
import {
    addUser,
    addUserError,
    loadAdminUserError,
    loadAdminUserSuccess,
    loadAdminUsers,
    loadAdminWithSearchSuccess,
    loadAgentUserError,
    loadAgentUserSuccess,
    loadAgentUsers,
    loadAgentWithSearchSuccess,
    loadTransporterUserError,
    loadTransporterUserSuccess,
    loadTransporterUsers,
    loadTransporterWithSearchSuccess,
    loadUserWithSearch,
    updateUser,
    updateUserError,
    updateUserPassword,
    userDetail,
    userDetailError,
    userDetailSuccess,
    userDetailbyId,
    userDetailbyIdSuccess,
    userOperationMessage,
} from '../actions/users.action'
import { UsersAPIService } from '../services/users.service'

@Injectable()
export class UsersAPIEffects {
    loadAdminUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadAdminUsers),
            mergeMap((param) =>
                this.usersAPIService.getUsers(param).pipe(
                    map((users) => loadAdminUserSuccess({ admins: users })),
                    catchError(async (error) => {
                        return loadAdminUserError({ error })
                    })
                )
            )
        )
    )

    loadAgentUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadAgentUsers),
            mergeMap((param) =>
                this.usersAPIService.getUsers(param).pipe(
                    map((users) => loadAgentUserSuccess({ agents: users })),
                    catchError(async (error) => loadAgentUserError({ error }))
                )
            )
        )
    )

    loadTransporterUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadTransporterUsers),
            mergeMap((param) =>
                this.usersAPIService.getUsers(param).pipe(
                    map((users) => loadTransporterUserSuccess({ transporters: users })),
                    catchError(async (error) => loadTransporterUserError({ error }))
                )
            )
        )
    )

    loadUserWithSearch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadUserWithSearch),
            mergeMap((param: SortSearchAndPaginationModal) => {
                return this.usersAPIService.getUsers(param).pipe(
                    map((users) => {
                        if (param.userListFilter?.role === 'admin') {
                            return loadAdminWithSearchSuccess({ admins: users })
                        }
                        if (param.userListFilter?.type === 'agents') {
                            return loadAgentWithSearchSuccess({ agents: users })
                        }
                        return loadTransporterWithSearchSuccess({ transporters: users })
                    }),
                    catchError(async (error) => loadAdminUserError({ error }))
                )
            })
        )
    )

    userDetail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userDetail),
            mergeMap((param) =>
                this.usersAPIService.getUserDetail(param).pipe(
                    map((user) => userDetailSuccess({ user: user })),
                    catchError(async (error) => {
                        return userDetailError({ error })
                    })
                )
            )
        )
    )

    userDetailbyId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userDetailbyId),
            mergeMap((param) =>
                this.usersAPIService.getUserDetailById(param).pipe(
                    map((user) => userDetailbyIdSuccess({ user: user })),
                    catchError(async (error) => {
                        return userDetailError({ error })
                    })
                )
            )
        )
    )

    /**add user */
    addUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addUser),
            mergeMap((param) => {
                return this.usersAPIService.addUser(param.user).pipe(
                    take(1),
                    map((user) => {
                        return userOperationMessage({
                            userType: param.user.type,
                            message: 'User Added Sucessfully',
                        })
                    }),
                    catchError(async (error) => addUserError({ error }))
                )
            })
        )
    )

    /**update user details */
    updateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUser),
            mergeMap((param: any) => {
                return this.usersAPIService.updateUserDetail(param.id, param.user).pipe(
                    map((user) => {
                        return userOperationMessage({
                            userType: param.user.type,
                            message: 'User Updated Sucessfully',
                        })
                    }),
                    catchError(async (error) => updateUserError({ error }))
                )
            })
        )
    )
    /**update password */
    updateUserPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUserPassword),
            mergeMap((param: any) => {
                return this.usersAPIService.updateUserPassword(param.id, param.payload).pipe(
                    map((user) => {
                        return userOperationMessage({
                            message: "User's Password Updated Sucessfully",
                        })
                    }),
                    catchError(async (error) => {
                        return updateUserError({ error })
                    })
                )
            })
        )
    )

    constructor(private actions$: Actions, private usersAPIService: UsersAPIService) {}
}

import { ILoadsState, InitialStateValue } from '@models/loads.model';
import { createReducer, on } from '@ngrx/store';
import {
  getLoadDetail,
  getLoadDetailById,
  getLoadDetailByIdFail,
  getLoadDetailByIdSuccess,
  getLoadDetailSuccess,
  loadLoadError,
  loadLoads,
  loadLoadSuccess,
  loadLoadWithSearch,
  resetStateAfterOperation,
  setActiveTab,
  setImageDetailToRenderInModal,
} from '../actions/load.action';
import { isString } from '@models/common.model';

/**user reducer for state change */
const initialState: ILoadsState = InitialStateValue;

export const loadReducer = createReducer(
  initialState,
  on(loadLoads, (state, action) => {
    return ({ ...state, paginationObj: action.pagination, loading: true })
  }),
  on(loadLoadSuccess, (state, action) => {
    return {
      ...state,
      loads: action.loads,
      paginationObj: action.loads.meta,
      loading: false
    }
  }),
  on(loadLoadError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(loadLoadWithSearch, (state, action) => {   
    return { ...state, searchObj: action.search, paginationObj: action.pagination, };
  }),
  on(setActiveTab, (state, action) => {
    return { ...state, activeTab: action.tab }
  }),

  on(getLoadDetailById, (state, action) => {
    return { ...state, loading: true }
  }),
  on(getLoadDetailByIdFail, (state, action) => {
    return { ...state, error: action.error }
  }),
  on(getLoadDetailSuccess, (state, action) => {
    return {
      ...state,
      load: action.load
    }
  }),
  on(getLoadDetailByIdSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      loadDetail: { loadDetail: action.loadDetail }
    }
  }),
  on(setImageDetailToRenderInModal, (state, action) => {
    return {
      ...state,
      imageData: action.imageData
    }
  }),
  on(resetStateAfterOperation, (state, action) => {
    return { ...state, loading: false, error: null, site: null, opSuccess: '' };
  })
);

import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {ManualLoadsService} from "@module/load-management/services/manual-loads.service";
import {getPermitDetailsAction} from "@module/load-management/store/actions";
import {catchError, switchMap} from "rxjs/operators";
import {LoadItem} from "@models/loads.model";
import {map, of} from "rxjs";
import {TransportPermitDetail, TransportPermitListAPIResponse} from "@models/transport-permit.model";


export const getLoadByRegNumberEffect = createEffect(
  (actions$ = inject(Actions), manualLoadsService = inject(ManualLoadsService)) => {
    return actions$.pipe(
      ofType(getPermitDetailsAction.requestPermitDetails),
      switchMap(({ regNumber, date, siteId }) => {
        return manualLoadsService.getPermitDetail(regNumber, date, siteId).pipe(
          map((data: TransportPermitDetail) => {
            return getPermitDetailsAction.requestPermitDetailsSuccess({ permitDetail: data });
          }),
          catchError((error) => {
            console.error(error)
            return of(getPermitDetailsAction.requestPermitDetailsFailed(error));
          }),
        );
        // return manualLoadsService.getPermitDetail(regNumber, date, siteId).pipe(
        //   map((data: TransportPermitDetail) => {
        //     return getPermitDetailsAction.requestPermitDetailsSuccess({ permitDetail: data })
        //   }),
        //   catchError((error) => {
        //     return of(getPermitDetailsAction.requestPermitDetailsFailed(error.toString()))
        //   }),
        // )
      }),
    )
  },
  { functional: true },
)

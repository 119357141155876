import {
  addTransportOrderError,
  getTransportOrder,
  getTransportOrderError,
  getTransportOrderSuccess,
  loadTransportOrder,
  loadTransportOrderError,
  loadTransportOrderSuccess,
  loadTransportOrderWithSearchSuccess,
  resetStateAfterOperation,
  transportOrderOperationMessage,
  updateTransportOrderError,
} from '@actions/transport-order.action';
import { ITransportOrderState } from '@models/transport-order.model';
import { createReducer, on } from '@ngrx/store';

/**user reducer for state change */
const initialState: ITransportOrderState = {
  paginationObj: {
    sortField: 'createdAt',
    sortDir: 'DESC',
    currentPage: 0,
    pageSize: 20,
  },
  transportOrdersList: {
    data: [],
    meta: {
      itemCount: 0,
      pageCount: 0,
      currentPage: 0,
      pageSize: 20,
    },
  },
  transportOrder: null,
  loading: false,
  error: null,
  opSuccess: '',
};

export const transportOrderReducer = createReducer(
  initialState,
  on(loadTransportOrder, (state, action) => {
    return { ...state, loading: true };
  }),
  on(loadTransportOrderSuccess, (state, { transportOrdersList }) => ({
    ...state,
    paginationObj: transportOrdersList.meta,
    transportOrdersList: transportOrdersList,
    loading: false,
  })),
  on(loadTransportOrderError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(loadTransportOrderWithSearchSuccess, (state, { transportOrdersList }) => ({
    ...state,
    paginationObj: transportOrdersList.meta,
    transportOrdersList,
    loading: false,
  })),
  on(getTransportOrder, (state, action) => {
    return { ...state, loading: true };
  }),
  on(getTransportOrderSuccess, (state, action) => {
    return { ...state, transportOrder: action.transportOrder, loading: false };
  }),
  on(getTransportOrderError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(updateTransportOrderError, (state, action) => {
    return { ...state, error: action.error, loading: false };
  }),
  on(addTransportOrderError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(transportOrderOperationMessage, (state, action) => {
    return { ...state, opSuccess: action.message };
  }),
  on(resetStateAfterOperation, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      contract: null,
      opSuccess: '',
    };
  })
);

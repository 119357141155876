import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { authReducer } from '@reducers/auth.reducer';
import { AuthenticationAPIService } from '@services/auth.service';
import { LoginComponent } from './login/login.component';
import { AuthEffects } from '@effects/auth.effects';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { AuthRoutingModule } from './auth-routing.module';

import { UsersAPIService } from '@services/users.service';
import { SharedModule } from '@shared/shared.module';
import { AuthGuard } from '@guard/auth.guard';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    RouterModule.forChild([
      {
        path: 'login',
        component: LoginComponent,
        data: { animation: 'Login' },
      },
    ]),
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects]),
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    SharedModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSelectModule,
  ],
  declarations: [LoginComponent],
  exports: [LoginComponent],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthenticationAPIService,
        UsersAPIService,
        AuthGuard                
      ],
    };
  }
}

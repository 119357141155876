import { createReducer, on } from '@ngrx/store';
import { User } from 'firebase/auth';
import { AuthActions } from '../actions/auth.actions';

export interface AuthState {
  user: User | undefined;
  requestedPath: String;
  error: String | undefined;
  loading?: boolean;
}

export const initialAuthState: AuthState = {
  user: undefined,
  requestedPath: '',
  error: undefined,
  loading: false,
};

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.authGuardBlockNoUser, (state, action) => {
    return {
      ...state,
      requestedPath: action.requestedPath,
    };
  }),
  on(AuthActions.authStateUser, (state, action) => {
    return {
      user: action.user,
      error: undefined,
      loading: false,
      requestedPath: state.requestedPath,
    };
  }),
  on(AuthActions.authStateNullUser, (state, action) => {
    return {
      user: undefined,
      error: undefined,
      loading: false,
      requestedPath: state.requestedPath,
    };
  }),
  on(AuthActions.loginRequest, (state, action) => {
    return {
      user: undefined,
      error: undefined,
      loading: true,
      requestedPath: state.requestedPath,
    };
  }),
  on(AuthActions.loginSuccess, (state, action) => {
    return {
      user: action.user,
      error: undefined,
      loading: false,
      requestedPath: state.requestedPath,
    };
  }),
  on(AuthActions.loginFail, (state, action) => {
    return {
      user: undefined,
      error: action.error,
      loading: false,
      requestedPath: '',
    };
  }),
  on(AuthActions.signOut, (state, action) => {
    return {
      user: undefined,
      error: undefined,
      requestedPath: '',
    };
  }),
  on(AuthActions.signedOut, (state, action) => {
    return {
      user: undefined,
      error: undefined,
      requestedPath: '',
    };
  })
);

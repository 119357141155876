import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, Subscription } from 'rxjs';
import { AuthActions } from '@actions/auth.actions';
import {
  getAuthLoading,
  getUserLoginError,
} from '@selectors/auth.selectors';
import { setLoadingSpinner } from '@store/shared/shared.actions';
import { LoginUserAPIRequest } from '@models/auth.model';
import { LoginPasswordValidationRegex } from 'src/app/constant/regex.constants';

import { getLoading } from '@store/shared/shared.selector';
import { SnackbarService } from '@shared/services/snackbar.service';
import { loginFormValidations } from 'src/app/constant/formvalidation.constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('inOut', [
      state(
        'in',
        style({
          opacity: 1,
        })
      ),
      state(
        'out',
        style({
          opacity: 0,
        })
      ),
      transition('in => out', [animate('0.3s ease-out')]),
      transition('out => in', [animate('0.3s ease-in')]),
    ]),
  ],
})
export class LoginComponent {
  loginForm: FormGroup;
  private onDestroy$: Subject<void> = new Subject<void>();
  IsLoading?: Observable<boolean>;
  showLoading: Subscription = new Subscription();
  errorData?: string;
  errorDataSubs: Subscription = new Subscription();
  logo_imgSrc = '/assets/images/cometa _logo _icon.svg';
  ValidationMessage = loginFormValidations;
  loading = false;
  hide = true;

  constructor(
    private store: Store<{}>,
    public dialog: MatDialog,
    private router: Router,
    private _snackbar: SnackbarService
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(LoginPasswordValidationRegex),
      ]),
    });
  }

  ngOnInit() {
    this.IsLoading = this.store.select(getLoading);
    this.showLoading = this.store
      .select(getAuthLoading)
      .subscribe((loading) => {
        this.store.dispatch(setLoadingSpinner({ status: loading || false }));
      });
    this.errorDataSubs = this.store
      .select(getUserLoginError)
      .subscribe((data) => {
        if (data) {
          this._snackbar.showErrorSnackbar({
            message: data!.toString(),
          });
        }
      });
  }


  public loginFormError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  };

  onLoginClick() {

    this.store.dispatch(
      AuthActions.loginRequest({
        loginRequest: this.loginForm.value})
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.onDestroy$.complete();
  }
}

/** effect for user list api call */

import {
  addOrganisation,
  addOrganisationError,
  getOrganisation,
  getOrganisationSuccess,
  loadOrganisationError,
  loadOrganisations,
  loadOrganisationSuccess,
  loadOrganisationsWithSearch,
  organisationOperationMessage,
  resetStateAfterOperation,
  resetStateSuccess,
  updateOrganisation,
  updateOrganisationError,
} from '@actions/organisation.action';
import { Injectable } from '@angular/core';
import {
  SortAndPaginationModal,
  SortSearchAndPaginationModal,
} from '@models/common.model';
import { OrganisationCreateAPIRequest } from '@models/organisations.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrganisationAPIService } from '@services/organisation.service';
import { catchError, map, mergeMap, of } from 'rxjs';

@Injectable()
export class OrganisationAPIEffects {
  loadOrganistion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrganisations),
      mergeMap((param: { pagination: SortAndPaginationModal }) => {
        return this.organisationAPIService.getOrganisations(param).pipe(
          map((organisations) => {
            return loadOrganisationSuccess({ organisations });
          }),
          catchError(async (error) => loadOrganisationError({ error }))
        );
      })
    )
  );

  //search organisation
  loadOrganisationsWithSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrganisationsWithSearch),
      mergeMap((param: SortSearchAndPaginationModal) => {
        return this.organisationAPIService.getOrganisations(param).pipe(
          map((organisations) => {
            return loadOrganisationSuccess({ organisations });
          }),
          catchError(async (error) => loadOrganisationError({ error }))
        );
      })
    )
  );

  // create effect for add organisation
  addContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addOrganisation),
      mergeMap((param) =>
        this.organisationAPIService.addOrganisation(param.organisation).pipe(
          map((organisation) =>
            organisationOperationMessage({
              message: 'Organisation Added Successfully',
            })
          ),
          catchError(async (error) => {
            return addOrganisationError({ error });
          })
        )
      )
    )
  );

  //get organisation details
  getOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrganisation),
      mergeMap((param: { id: number | string }) => {
        return this.organisationAPIService.getOrganisationDetail(param.id).pipe(
          map((organisationDetail) => {
            return getOrganisationSuccess({ organisation: organisationDetail });
          }),
          catchError(async (error) => loadOrganisationError({ error }))
        );
      })
    )
  );

  //update organisation
  updateOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganisation),
      mergeMap(
        (param: {
          id: number | string;
          organisation: OrganisationCreateAPIRequest;
        }) => {
          return this.organisationAPIService
            .updateOrganisationDetail(param)
            .pipe(
              map(() => {
                return organisationOperationMessage({
                  message: 'Organisation Updated Successfully',
                });
              }),
              catchError(async (error) => {
                return updateOrganisationError({ error });
              })
            );
        }
      )
    )
  );

  resetStateAfterOperation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resetStateAfterOperation),
      mergeMap(() => {
        return of(resetStateSuccess());
      })
    );
  });

  constructor(
    private actions$: Actions,
    private organisationAPIService: OrganisationAPIService
  ) {}
}

import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, switchMap, map, tap, take } from 'rxjs/operators';
import { AuthActions } from '../actions/auth.actions';
import { from, of } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectRequestedPath } from '../selectors/auth.selectors';
import { AuthenticationAPIService } from '../services/auth.service';
import { User } from 'firebase/auth';
import { UsersAPIService } from '../services/users.service';
import { ErrorHandlerService } from 'src/app/shared/services/errorhandler.service';
import { AppPathLink } from 'src/app/constant/router.constants';

@Injectable()
export class AuthEffects {
  authGuardBlockNoUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authGuardBlockNoUser),
      switchMap((action) => {
        return this.AuthenticationAPIService.authState().pipe(
          switchMap((value) => {
            if (value) {
              return this.UsersAPIService.getUserDetail({
                by: 'uid',
                uid: value.uid,
              }).pipe(
                map((value) => {
                  return AuthActions.authStateUser({
                    user: value,
                  });
                }),
                catchError((err) => of(AuthActions.loginFail({ error: err })))
              );
            } else {
              return of(AuthActions.authStateNullUser());
            }
          })
        );
      })
    );
  });

  authStateUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.authStateUser),
        switchMap((action) => {
          return this.store.select(selectRequestedPath).pipe((path) => {
            return path;
          });
        }),
        tap((requestedPath) => {
          this.router.navigateByUrl(<string>requestedPath);
        })
      );
    },
    {
      dispatch: false,
    }
  );

  authStateNullUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.authStateNullUser),
        switchMap((action) => {
          return this.store.select(selectRequestedPath).pipe((path) => {
            return path;
          });
        }),
        tap((requestedPath) => {
          this.router.navigate(['/' + AppPathLink.LOGIN], {
            queryParams: { redirect: <string>requestedPath },
          });
        })
      );
    },
    {
      dispatch: false,
    }
  );

  loginRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginRequest),
      switchMap((action) =>
        action.loginRequest
          ? this.AuthenticationAPIService.loginUser(action.loginRequest).pipe(
              switchMap((userDetail) =>
                userDetail
                  ? this.UsersAPIService.getUserDetail({
                      by: 'uid',
                      uid: userDetail.user?.uid,
                    }).pipe(
                      map((value) =>
                        AuthActions.loginSuccess({ user: value as User })
                      ),
                      catchError((err) =>
                        of(
                          AuthActions.loginFail({
                            error: err.error?.message
                              ? err.error.message
                              : this.errorHandler.convertAuthMessage('unknown'),
                          })
                        )
                      )
                    )
                  : of(
                      AuthActions.loginFail({
                        error: this.errorHandler.convertAuthMessage('500'),
                      })
                    )
              ),
              catchError((err) =>
                of(
                  AuthActions.loginFail({
                    error: this.errorHandler.convertAuthMessage(err['code']),
                  })
                )
              )
            )
          : of(AuthActions.loginFail({ error: 'Invalid login request' }))
      )
    )
  );
  loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        switchMap((action) => {
          return this.store.select(selectRequestedPath).pipe((path) => {
            return path;
          });
        }),
        tap((requestedPath) => {
          this.router.navigate([AppPathLink.OPERATION_MANAGEMENT]);
        })
      );
    },
    {
      dispatch: false,
    }
  );

  signOut$ = createEffect(
    // using createEffect adds error handling and avoids a manual subscription to the observable
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.signOut),
        switchMap((action) => {
          return this.AuthenticationAPIService.signOut().pipe(
            map((val) => {
              return AuthActions.signedOut();
            })
          );
        })
      );
    }
  );

  constructor(
    private actions$: Actions,
    private AuthenticationAPIService: AuthenticationAPIService,
    private UsersAPIService: UsersAPIService,
    private router: Router,
    private store: Store<{}>,

    private errorHandler: ErrorHandlerService
  ) {}
}

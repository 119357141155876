/** All api calls for the authetication module */

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from, of, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { LoginUserAPIRequest } from '../models/auth.model';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationAPIService {
  public username: string = '';
  public displayName: string = '';
  public idToken: string = '';

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    
  ) {
    this.auth.user
      .pipe(
        switchMap((user) => {          
          if (user) {
            this.username = user.email!;
            this.displayName = user.displayName!;
            return from(user.getIdToken());
          } else {
            this.username = '';
            this.displayName = '';
            return of(null);
          }
        }),
        switchMap((val) => {
          if (val) {
            this.idToken = val;
          } else {
            this.idToken = '';
          }
          return of(null);
        })
      )
      .subscribe(() => {
        console.log('User info refreshed');
      });
  }

  loginUser(credentials: LoginUserAPIRequest) {    
    return from(
      this.auth.signInWithEmailAndPassword(
        credentials.email,
        credentials.password
      )
    );
  }

  authState() {
    return this.auth.authState;
  }

  signOut() {
    
    return from(this.auth.signOut());
  }
}

import { IDropdown } from './../models/common.model';
import { createReducer, on } from '@ngrx/store';
import {
  loadSiteError,
  loadSites,
  loadSiteSuccess,
  siteOperationMessage,
  getSiteSuccess,
  getSite,
  resetStateAfterOperation,
  getSiteError,
  loadSiteWithSearch,
  loadSiteWithSearchSuccess,
  loadSiteTypes,
  loadSiteTypeSuccess
} from '../actions/site.action';
import { ISitesState } from '../models/sites.model';

/**user reducer for state change */
export const InitialState: ISitesState = {
  paginationObj: {
    'sortField': '',
    'sortDir': 'ASC',
    'currentPage': 0,
    'pageSize': 20
  },
  sites: {
    data: [], meta: {
      itemCount: 0,
      pageCount: 0,
      currentPage: 0,
      pageSize: 0
    }
  },
  site: null,
  loading: false,
  error: null,
  opSuccess: '',
};

export const siteReducer = createReducer(
  InitialState,
  on(loadSites, (state, action) => {
    return ({ ...state, loading: true })
  }),
  on(loadSiteWithSearch, (state, action) => {
    return ({ ...state })
  }),
  on(loadSiteSuccess, (state, { sites }) => ({
    ...state,
    paginationObj: sites.meta,
    sites,
    loading: false,
  })),
  on(loadSiteWithSearchSuccess, (state, { sites }) => ({
    ...state,
    paginationObj: sites.meta,
    sites,
    loading: false,
  })),
  on(loadSiteError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(getSiteError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(siteOperationMessage, (state, action) => {
    return { ...state, opSuccess: action.message };
  }),
  on(getSite, (state) => {
    return { ...state, loading: true };
  }),
  on(getSiteSuccess, (state, action) => {
    return { ...state, site: action.site, loading: false };
  }),
  on(resetStateAfterOperation, (state, action) => {
    return { ...state, loading: false, error: null, site: null, opSuccess: '' };
  }),
  on(loadSiteTypes, (state, action) => {
    return { ...state, loading: true };
  }),
  on(loadSiteTypeSuccess, (state, action) => {
    return { ...state, loading: false };
  })
);

/** effect for contract list api call */

import { Injectable } from '@angular/core';
import { ContractCreateAPIRequest } from '@models/contract.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs';
import {
  addContract,
  addContractError,
  contractDetail,
  contractDetailError,
  contractDetailSuccess,
  contractOperationMessage,
  loadContractWithSearch,
  loadContractWithSearchSuccess,
  loadContracts,
  loadContractsError,
  loadContractsSuccess,
  updateContract,
  updateContractError,
} from '../actions/contract.action';
import { ContractAPIService } from '../services/contract.service';

@Injectable()
export class ContractAPIEffects {
  loadContracts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContracts),
      mergeMap((param) =>
        this.ContractAPIService.getContracts(param).pipe(
          map((contracts) => loadContractsSuccess({ contracts: contracts })),
          catchError(async (error) => {
            return loadContractsError({ error });
          })
        )
      )
    )
  );

  contractDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contractDetail),
      mergeMap((param) =>
        this.ContractAPIService.getContractDetail(param.id).pipe(
          map((contract) => contractDetailSuccess({ contract: contract })),
          catchError(async (error) => {
            return contractDetailError({ error });
          })
        )
      )
    )
  );

  // create effect for addContract
  addContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addContract),
      mergeMap((param) =>
        this.ContractAPIService.addContract(param.contract).pipe(
          map((contract) =>
            contractOperationMessage({ message: 'Contract Added Successfully' })
          ),
          catchError(async (error) => {
            return addContractError({ error });
          })
        )
      )
    )
  );

  updateContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateContract),
      mergeMap(
        (param: { id: string | number; contract: ContractCreateAPIRequest }) =>
          this.ContractAPIService.updateContractDetail(
            param.id,
            param.contract
          ).pipe(
            map((contract) => {
              return contractOperationMessage({
                message: 'Contract Updated Successfully!',
              });
            }),
            catchError(async (error) => updateContractError({ error }))
          )
      )
    )
  );

  loadContractWithSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContractWithSearch),
      mergeMap((param) => {
        return this.ContractAPIService.getContracts(param).pipe(
          map((contracts) => {
            return loadContractWithSearchSuccess({ contracts: contracts });
          }),
          catchError(async (error) => loadContractsError({ error }))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private ContractAPIService: ContractAPIService
  ) {}
}

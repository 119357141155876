/** Service HTTP call */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UserManagementAPIConstant } from 'src/app/constant/api.constants';
import { environment } from 'src/environments/environment';
import {
  UsersListResponse,
  CreateAdminUser,
  UserDetails, UsersListApiResponse, UpdateUserPasword,
} from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UsersAPIService {
  constructor(private http: HttpClient) { }

  /**API call to get user list */

  getUsers(param: any): Observable<UsersListResponse> {
    console.log("param===>>",param)
    const pageNumber = param.pagination.currentPage ? param.pagination.currentPage : 0
    const pageSize = param.pagination.pageSize ? param.pagination.pageSize : 20
    let type = param.userListFilter.type && param.userListFilter.type !== "" ? `&type=${param.userListFilter.type}` : ''
    let role = param.userListFilter.role && param.userListFilter.role !== "" ? `&role=${param.userListFilter.role}` : ''
    let querySearchString = param.search && `&queryString=${param.search}` || ''
    const sortField = param.pagination.sortField
    const sortDir = param.pagination.sortDir || 'ASC'
    let queryPara = "";
    if (sortDir && sortField) {
      queryPara = `?sortField=${sortField}&sortDir=${sortDir.toUpperCase()}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    } else {
      queryPara = `?sortDir=${sortDir}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<UsersListApiResponse>(
      environment.apiBaseUrl +
      UserManagementAPIConstant.USERS +
      queryPara +
      type + role + querySearchString
    ).pipe(
      map((apiResponse: UsersListApiResponse) => ({
        paginationObj: {
          itemCount: apiResponse.totalCount,
          pageCount: apiResponse.totalPages,
          currentPage: apiResponse.pageNumber,
          pageSize: pageSize
        },
        data: apiResponse.items,
        meta: {
          itemCount: apiResponse.totalCount,
          pageCount: apiResponse.totalPages,
          currentPage: apiResponse.pageNumber,
          pageSize: pageSize
        }
      })
      )
    );
  }

  /** API to get user details */
  getUserDetail(param: any): Observable<any> {
    return this.http.get<any>(
      environment.apiBaseUrl +
      UserManagementAPIConstant.USERS +
      '/uid/' +
      param.uid
    );
  }

  /**Api to get user by id */
  getUserDetailById(param: any) {
    return this.http.get<any>(
      environment.apiBaseUrl +
      UserManagementAPIConstant.USERS +
      '/id/' +
      param.id
    );
  }

  /** Add User */
  addUser(param: CreateAdminUser) {
    return this.http.post<UserDetails>(
      environment.apiBaseUrl + UserManagementAPIConstant.USERS,
      param
    );
  }

  /** Update */
  updateUserDetail(id: string | number, param: CreateAdminUser) {
    return this.http.patch<UserDetails>(
      environment.apiBaseUrl + UserManagementAPIConstant.USERS + '/' + id,
      param
    );

  }

  /** Update Password */
  updateUserPassword(id: string | number, param: UpdateUserPasword) {
    return this.http.post<any>(
      environment.apiBaseUrl + UserManagementAPIConstant.USERS + '/' + id + '/password',
      param
    );
  }

  /** Delete */
  deleteUserDetail(id: string | number) {
    return this.http.delete<UserDetails>(
      environment.apiBaseUrl + UserManagementAPIConstant.USERS + '/' + id
    );
  }
}

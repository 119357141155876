/** effect for user list api call */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs';

import {
  addTransportOrder,
  addTransportOrderError,
  loadTransportOrder,
  loadTransportOrderError,
  loadTransportOrderSuccess,
  loadTransportOrderWithSearch,
  loadTransportOrderWithSearchSuccess,
  TransportOrderActions,
  transportOrderOperationMessage,
} from '@actions/transport-order.action';
import {
  TransportOrderActionId,
  TransportOrderDetail,
} from '@models/transport-order.model';
import { TransportOrderAPIService } from '@services/transport-order.service';

@Injectable()
export class TransportOrderEffects {
  loadTransportOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTransportOrder),
      mergeMap((param) =>
        this.transportOrderAPIService.getTransportOrderList(param).pipe(
          map((transportOrder) =>
            loadTransportOrderSuccess({ transportOrdersList: transportOrder })
          ),
          catchError(async (error) => {
            return loadTransportOrderError({ error });
          })
        )
      )
    )
  );

  addContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTransportOrder),
      mergeMap((param) =>
        this.transportOrderAPIService
          .addTransportOrder(param.transportOrder)
          .pipe(
            map((order) =>
              transportOrderOperationMessage({
                message: 'Transport order Added Successfully',
              })
            ),
            catchError(async (error) => {
              return addTransportOrderError({ error });
            })
          )
      )
    )
  );

  getTransportOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransportOrderActions.getTransportOrder),
      mergeMap((param: TransportOrderActionId) =>
        this.transportOrderAPIService.getTransportOrderDetail(param.id).pipe(
          map((order) =>
            TransportOrderActions.getTransportOrderSuccess({
              transportOrder: order,
            })
          ),
          catchError(async (error) => {
            return TransportOrderActions.getTransportOrderError({ error });
          })
        )
      )
    )
  );

  updateTransportOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransportOrderActions.updateTransportOrder),
      mergeMap(
        (param: {
          id: string | number;
          transportOrder: TransportOrderDetail;
        }) =>
          this.transportOrderAPIService
            .updateTransportOrderDetail(param.id, param.transportOrder)
            .pipe(
              map((order) =>
                transportOrderOperationMessage({
                  message: 'Transport order updated Successfully',
                })
              ),
              catchError(async (error) => {
                return TransportOrderActions.updateTransportOrderError({
                  error,
                });
              })
            )
      )
    )
  );

  loadTransportOrderWithSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTransportOrderWithSearch),
      mergeMap((param) => {
        return this.transportOrderAPIService.getTransportOrderList(param).pipe(
          map((transportOrder) => {
            return loadTransportOrderWithSearchSuccess({
              transportOrdersList: transportOrder,
            });
          }),
          catchError(async (error) => loadTransportOrderError({ error }))
        );
      })
    )
  );

  constructor(
    private transportOrderAPIService: TransportOrderAPIService,
    private actions$: Actions
  ) {}
}

import { IContractState } from '@models/contract.model';
import { createReducer, on } from '@ngrx/store';
import {
  addContractError,
  contractDetail,
  contractDetailError,
  contractDetailSuccess,
  contractOperationMessage,
  loadContracts,
  loadContractsError,
  loadContractsSuccess,
  loadContractWithSearch,
  loadContractWithSearchSuccess,
  resetStateAfterOperation,
  setActiveTab,
  updateContractError,
} from '../actions/contract.action';

/**contract reducer for state change */
const initialState: IContractState = {
  paginationObj: {
    sortField: 'createdAt',
    sortDir: 'DESC',
    currentPage: 0,
    pageSize: 20,
  },
  contracts: {
    data: [],
    meta: {
      itemCount: 0,
      pageCount: 0,
      currentPage: 0,
      pageSize: 20,
    },
  },
  contract: null,
  loading: false,
  error: null,
  opSuccess: '',
  opFail: '',
  activeTab: 0,
};

export const contractReducer = createReducer(
  initialState,
  on(loadContracts, (state, action) => {
    return { ...state, loading: true };
  }),
  on(loadContractsSuccess, (state, { contracts }) => ({
    ...state,
    paginationObj: contracts.meta,
    contracts,
    loading: false,
  })),
  on(loadContractsError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(loadContractWithSearchSuccess, (state, { contracts }) => ({
    ...state,
    paginationObj: contracts.meta,
    contracts,
    loading: false,
  })),
  on(contractDetailSuccess, (state, action) => {
    return { ...state, contract: action.contract, loading: false };
  }),
  on(contractDetail, (state, action) => {
    return { ...state, loading: true };
  }),
  on(contractDetailError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(updateContractError, (state, action) => {
    return { ...state, error: action.error, loading: false };
  }),
  on(setActiveTab, (state, action) => {
    return { ...state, activeTab: action.tab };
  }),
  on(addContractError, (state, action) => {
    return { ...state, error: action.error.message, loading: false };
  }),
  on(contractOperationMessage, (state, action) => {
    return { ...state, opSuccess: action.message };
  }),
  on(resetStateAfterOperation, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      contract: null,
      opSuccess: '',
    };
  })
);

import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';
import { User } from 'firebase/auth';
import { AuthActions } from '@actions/auth.actions';
import { selectUser } from '@selectors/auth.selectors';

@Injectable()
export class AuthGuard {
  public userData: string = '';
  constructor(private store: Store<{}>) {}

  ngOnInit() {
    this.store.select(selectUser).subscribe((val) => {
      this.userData = val?.uid || '';
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {   
    return this.store.pipe(
      select(selectUser),
      take(1),
      switchMap((user: User | undefined) => {
        if (user) {
          return of(true);
        } else {
          let token = this.userData;
          if (token && token !== '') {
            return of(true);
          } else {
            this.store.dispatch(
              AuthActions.authGuardBlockNoUser({
                requestedPath: state.url,
              })
            );
            return of(false);
          }
        }
      })
    );
  }
}
